<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        Will you use these in the project?
        <v-tooltip top max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on"> mdi-information-outline </v-icon>
          </template>
          <span
            >Selecting these options will tailor the questions in the next page
            of the DIP</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="selectedList"
          value="BIM"
          @change="updateStore()"
          label="BIM"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedList"
          @change="updateStore()"
          label="GIS and/or Geospatial"
          value="GIS"
        ></v-checkbox>
        <v-checkbox
          v-model="selectedList"
          @change="updateStore()"
          disabled
          label="Digital Product Development (Coming Soon)"
          value="Product Development"
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _ from "lodash";
import mixin from "../mixins/mixin.js";
export default {
  mixins: [mixin],
  name: "App",
  props: {},
  components: {},

  data: () => ({
    selectedListProxy: [],
  }),
  computed: {
    selectedList: {
      get() {
        return this.$store.state.plan.DigitalPlan.ProjectSpecificChoices;
      },
      set(val) {
        this.selectedListProxy = val;
      },
    },
  },
  mounted() {
    this.selectedListProxy =
      this.$store.state.plan.DigitalPlan.ProjectSpecificChoices;
  },

  methods: {
    async updateStore() {
      this.$store.state.plan.DigitalPlan.ProjectSpecificChoices =
        this.selectedListProxy;
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "ProjectSpecificChoices"
      );
    },
  },
};
</script>
